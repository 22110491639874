import { Link } from "@remix-run/react";

type Props = {
  small?: boolean;
};

/**
 * The application logo
 * @param props containing
 * small: boolean indicating if the logo should appear in small or normal size
 */
export default ({ small }: Props) => {
  return (
    <Link to="/">
      <div className="flex flex-row gap-4 items-center overflow-x-hidden">
        <div className="bg-secondary-main-100 w-[40px] h-[40px] min-w-[40px] rounded-sm" />
        {!small && (
          <div className="flex flex-col gap-[5px]">
            <span className="text-sm text-secondary-main-100 font-medium text-nowrap">
              Geeng
            </span>
            <span className="text-lg text-secondary-main-100 font-semibold text-nowrap">
              CHU Rennes
            </span>
          </div>
        )}
      </div>
    </Link>
  );
};
